// 场景配置
export const SCENE_CONFIG = {
    // ================ 测试场景勿使用和删除 ================
    avator45: { q: 50, w: 90 },
    avator135: { q: 50, w: 270, format: "webp" },
    //=================================================
    RichText: { q: 80, w: 800 },
    CourseList: { q: 75, w: 360, format: "webp" },
    CourseList_Sm: { q: 75, w: 140, format: "webp" },
    CourseList_Mini: { q: 60, w: 100, format: "webp" },
    PC_Banner: { q: 90, format: "webp" },
    H5_Banner: { q: 90, w: 900, format: "webp" },
    UploadCover_Big: { q: 75, w: 600, format: "webp" },
    UploadCover: { q: 75, w: 210, format: "webp" },
    Avator_80: { q: 60, w: 160, format: "webp" },
    Avator_24: { q: 60, w: 48, format: "webp" },
    Avator_16: { q: 60, w: 32, format: "webp" },
};
// 正则占位符
export const PATTERNS_PLACEHOLDER = {
    BUCKET: '[a-zA-Z0-9-_]+', // bucket 的正则表达式模式
    OS: ['cos', 'obs', 'tos'], // 已知的 cloud 值列表
    EXT: ['acc'], // 已知的 ext 值列表
    BNUMBER: '(?!10011692|1251748120)\\d+', // 由于存在新规范多环境重名情况，不对现网老桶的开发测试环境桶进行处理
    DOMAIN: [
        // 现网环境
        "knowlink-materials.com",
        'kl-materials.com',
        'xiaoe-materials.com',
        'xe-materials.com',
        // 测试环境
        "test.knowlink-materials.com",
        'test.kl-materials.com',
        'test.xiaoe-materials.com',
        'test.xe-materials.com'
    ]
};
const COS_CDN_TEMPLATE = "{BUCKET}-cos.cdn.xiaoe-materials.com";
// TODO:需要区分测试和现网环境,当前先默认现网环境
// 定义正则表达式模板，保持原始格式
export const REPLACE_TEMPLATE = {
    cos: [
        ["({BUCKET})-({BNUMBER}).picsh.myqcloud.com", COS_CDN_TEMPLATE],
        ["({BUCKET})-({BNUMBER}).file.myqcloud.com", COS_CDN_TEMPLATE],
        ["({BUCKET})-({BNUMBER}).cossh.myqcloud.com", COS_CDN_TEMPLATE],
        ["({BUCKET})-({BNUMBER}).cdn.xiaoeknow.com", COS_CDN_TEMPLATE],
        ["({BUCKET})-({BNUMBER}).cos.accelerate.myqcloud.com", COS_CDN_TEMPLATE],
        ["({BUCKET})-({BNUMBER}).cos.ap-[^.]*.myqcloud.com", COS_CDN_TEMPLATE],
    ],
    // tos: ...
    // obs: ...
};
// 规范详见：https://doc.weixin.qq.com/doc/w3_AH8A4AbDACUhFtCFx1YRvCSnF0W5i?scode=ALQAnAdhAAYmXckLVKAH8A4AbDACU&is_external=0&commentVersion=1728529935000&doc_title=%E5%9F%9F%E5%90%8D%E6%9B%BF%E6%8D%A2%E9%97%AE%E9%A2%98&wxworkQt=1&qt_source=Conv&qt_report_identifier=1728701578088&version=4.1.27.70423&platform=mac
export const DEFAULT_REPLACE_TEMPLATE = [
    // CDN -> CDN
    // buketname-os.cdn.domain.com => buketname-os.cdn.domain.com
    [
        '({BUCKET})-({OS}).cdn.({DOMAIN})',
        '{BUCKET}-{OS}.cdn.{DOMAIN}'
    ],
    // withExtCOS -> CDN
    // buketname-ext.os.domain.com => buketname-os.cdn.domain.com
    [
        '({BUCKET})-({EXT}).({OS}).({DOMAIN} )',
        '{BUCKET}-{OS}.cdn.{DOMAIN}'
    ],
    // COS -> CDN
    // buketname.os.domain.com => buketname-os.cdn.domain.com
    [
        '({BUCKET}).({OS}).({DOMAIN})',
        '{BUCKET}-{OS}.cdn.{DOMAIN}'
    ],
];
export const PATTERNS_PLACEHOLDER_KEYS = Object.keys(PATTERNS_PLACEHOLDER);
export const REPLACEMENTS = PATTERNS_PLACEHOLDER_KEYS.reduce((obj, key) => {
    const rule = PATTERNS_PLACEHOLDER[key];
    obj[key] = Array.isArray(rule) ? rule.join("|") : rule;
    return obj;
}, {});
