import { DEFAULT_REPLACE_TEMPLATE, PATTERNS_PLACEHOLDER, PATTERNS_PLACEHOLDER_KEYS, REPLACE_TEMPLATE, REPLACEMENTS } from "@/const/config";
export const PATTERNS_CONFIG = createPatternsConfig();
// 创建链接正则匹配配置
export function createPatternsConfig() {
    let patternsConfig = DEFAULT_REPLACE_TEMPLATE
        .map(([template, cdnTemplate]) => handleAnalyzeTemplate(template, cdnTemplate));
    PATTERNS_PLACEHOLDER.OS.forEach(os => {
        const osPatternsConfig = (REPLACE_TEMPLATE[os] || [])
            .map(([template, cdnTemplate]) => handleAnalyzeTemplate(template, cdnTemplate, os));
        osPatternsConfig.length && patternsConfig.push(...osPatternsConfig);
    });
    return patternsConfig;
}
// 生成单个占位符模板配置
export function handleAnalyzeTemplate(template, cdnTemplate, os = "") {
    return { ...analyzeTemplate(REPLACEMENTS, template, os), cdnTemplate };
}
// 解析占位符模板
export function analyzeTemplate(placeholders, template, os = "") {
    const templateNode = {
        os,
        match: {},
        regex: new RegExp(''),
        transfer: ''
    };
    let regexIdx = 1;
    const transfer = template.replace(/{(\w+)}/g, (match, key) => {
        const replaceData = placeholders[key];
        if (replaceData) {
            templateNode.match[key] = regexIdx++;
            return replaceData;
        }
        return match;
    });
    templateNode.transfer = transfer;
    templateNode.regex = new RegExp(transfer);
    return templateNode;
}
// 解析url参数
export function parseUrlParams(url) {
    // 遍历配置，匹配正则表达式
    for (const replaceTemplate of PATTERNS_CONFIG) {
        const templateData = getTempleteNodeData(url, replaceTemplate);
        // 获取待替换的模板节点以及最终的CDN模板节点
        if (templateData)
            return templateData;
    }
    // 未找到匹配时返回 null
    return null;
}
// 获取配置中模版的节点数据
export function getTempleteNodeData(url, templateNode) {
    // 简化参数获取逻辑
    const getParams = (matchData, matchConfig, key) => {
        const value = matchConfig[key];
        return value && matchData[value] !== undefined ? matchData[value] : '';
    };
    const { regex, match, cdnTemplate } = templateNode;
    const matchData = regex.exec(url);
    // 找到匹配后立即返回结果
    if (matchData) {
        const data = {};
        const placeholders = {};
        PATTERNS_PLACEHOLDER_KEYS.forEach(key => {
            let value = getParams(matchData, match, key);
            data[key.toLowerCase()] = value;
            placeholders[key] = value;
        });
        // 解析cdn模板，将模板替换成真实数据
        const cdnNode = analyzeTemplate(placeholders, cdnTemplate);
        return {
            ...data,
            cdn: cdnNode.transfer,
            url,
            replace: matchData[0]
        };
    }
    return null;
}
