import platformApi from "@/platform/index";
import { LOCAL_WEBP_MARK, V2_MARK, CATCH_ERROR_MARK, STATIC_FORMATS } from "@/const/index";
import { getFileSuffix, addParamToURL, urlParams2Object } from "./index";
import { parseUrlParams } from "./config";
let webpSupportCache = null;
export const setWebpSupportCache = (value) => {
    webpSupportCache = value;
};
// 判断本地是否支持webp标识，可能标识并未更新，通过 setWebpSupportMark 更新标识
export const useMarkCheckWebpSupport = () => {
    // 内存webp标识
    if (webpSupportCache !== null)
        return webpSupportCache;
    // 本地webp标识
    const localWebp = platformApi.getStorage(LOCAL_WEBP_MARK);
    if (localWebp != undefined) {
        return (webpSupportCache = localWebp === "1");
    }
    return false;
};
// 生成数据万象参数
export const genFormatCI = (data = {}) => {
    const { type = 2, ...config } = data;
    const keys = Object.keys(config);
    if (!keys.length) {
        return "";
    }
    let ci = `${V2_MARK}/${type}`;
    keys.forEach((key) => {
        // @ts-ignore
        ci += `/${key}/${config[key]}`;
    });
    ci += CATCH_ERROR_MARK;
    return encodeURI(ci);
};
// 调整 config 的格式转换逻辑
export const adjustConfigForFormat = (fileSuffix, config = {}) => {
    // GIF 动态图片不能转成静态格式
    if (fileSuffix === "gif" &&
        config.format &&
        STATIC_FORMATS.includes(config.format)) {
        delete config.format;
    }
    // 如果浏览器不支持 WebP，删除 WebP 格式配置
    if (config.format === "webp") {
        const supportWebp = useMarkCheckWebpSupport();
        !supportWebp && delete config.format;
    }
    return config;
};
// 生成格式化后图片链接
export const genFormatUrl = (url, config = {}) => {
    const [_url, ...params] = url.split("?");
    // url参数
    const urlParamsString = [...params].join('?');
    const fileSuffix = getFileSuffix(_url);
    // 是否为私有桶链接
    const isPrivate = !!urlParams2Object(url)?.sign;
    const parseNode = parseUrlParams(_url);
    // 不处理无法替换的 URL
    if (!parseNode)
        return url;
    // 如果参数有sign说明是私有桶CDN不进行url替换 
    const replaceDomain = isPrivate ? parseNode.replace : parseNode.cdn;
    let formattedUrl = _url.replace(parseNode.replace, replaceDomain);
    formattedUrl = addParamToURL(formattedUrl, urlParamsString);
    // svg文件只进行cdn替换,不添加CI参数
    if (fileSuffix === "svg")
        return formattedUrl;
    adjustConfigForFormat(fileSuffix, config);
    return addParamToURL(formattedUrl, genFormatCI(config));
};
// 设置 webp 支持程度标识
export const setWebpSupportMark = async () => {
    const isSupportWebp = await platformApi.checkWebp();
    platformApi.setStorage(LOCAL_WEBP_MARK, isSupportWebp ? "1" : "0");
};
