// 获取文件后缀名
export const getFileSuffix = (item = "") => item.split(".").pop()?.toLowerCase() || "";
export const sleep = (time = 1000) => {
    return new Promise(resolve => {
        setTimeout(resolve, time);
    });
};
// 添加参数到链接中
export const addParamToURL = (url, param) => {
    if (!param)
        return url;
    return url.includes("?") ? `${url}&${param}` : `${url}?${param}`;
};
export const getNestedData = (obj, path) => {
    const keys = path.split('.');
    let current = obj;
    for (let i = 0; i < keys.length - 1; i++) {
        if (!current[keys[i]]) {
            current[keys[i]] = {};
        }
        current = current[keys[i]];
    }
    let lastAttr = keys[keys.length - 1];
    return [current, lastAttr];
};
export function urlParams2Object(url) {
    if (!url.includes('?'))
        return {};
    //获取字符串 ? 后面的部分
    const queryString = url.split('?')[1];
    const paramsArr = queryString.split('&');
    const paramObj = {};
    paramsArr.forEach(param => {
        const [key, value] = param.split('=');
        paramObj[key] = decodeURIComponent(value);
    });
    return paramObj;
}
