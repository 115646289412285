// 获取localstorage
function getWebStorage(key) {
    try {
        return localStorage.getItem(key);
    }
    catch (e) { }
}
// 设置localstorage
function setWebStorage(key, data) {
    try {
        localStorage.setItem(key, data);
    }
    catch (e) {
    }
}
// web查看是否支持webp
// 不使用canvas.todataUrl是因为兼容度差只有 75+%且不支持safari，而webp有96+%而且sdk后续可能拓展成异步的，将所有异步提前到init调用
function checkWebWebp() {
    return new Promise((resolve) => {
        var img = new Image();
        img.onload = function () {
            var result = img.width > 0 && img.height > 0;
            resolve(!!result);
        };
        img.onerror = function () {
            resolve(false);
        };
        img.src =
            "data:image/webp;base64,UklGRi4AAABXRUJQVlA4TCEAAAAvAUAAEB8wAiMwAgSSNtse/cXjxyCCmrYNWPwmHRH9jwMA";
    });
}
export default {
    checkWebp: checkWebWebp,
    getStorage: getWebStorage,
    setStorage: setWebStorage
};
