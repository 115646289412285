import { INIT_STATUS } from "@/const/index";
import { SCENE_CONFIG } from "@/const/config";
import { setWebpSupportMark, genFormatUrl } from "@/utils/tools";
import { getNestedData } from "@/utils/index";
var DirectiveType;
(function (DirectiveType) {
    DirectiveType["DEFAULT"] = "default";
    DirectiveType["BACKGROUND"] = "background";
})(DirectiveType || (DirectiveType = {}));
const eImageX = {
    initStatus: INIT_STATUS.READY
};
eImageX.initStatus = INIT_STATUS.READY;
eImageX.initPms = (async () => {
    eImageX.initStatus = INIT_STATUS.DOING;
    await setWebpSupportMark();
    eImageX.initStatus = INIT_STATUS.DONE;
})();
// 公共替换方法
eImageX.processUrls = (scene, urls, config) => {
    let sceneConfig = SCENE_CONFIG[scene];
    // 支持自定义场景
    if (scene == 'custom') {
        const defaultConfig = { type: '2', q: '80' };
        sceneConfig = { ...defaultConfig, ...config };
    }
    if (!sceneConfig) {
        throw new Error(`\n 需要填入符合规范的 scene，如有需求可联系对应组件开发 \n 【详见 https://fe.xiaoe-tools.com/portal/docs/imagex/imagex.html 】`);
    }
    if (Array.isArray(urls)) {
        return urls.map(url => genFormatUrl(url, sceneConfig));
    }
    return genFormatUrl(urls, sceneConfig);
};
// 同步方法（能力有损，在【initPms初始化完成前】异步配置返回前就返回处理后链接，期间使用兜底能力）
eImageX.sync = (scene, urls, config = {}) => {
    return eImageX.processUrls(scene, urls, config);
};
// 异步方法（等待【initPms初始化完成】异步配置返回后返回对应内容）
eImageX.async = async (scene, urls, config = {}) => {
    await eImageX.initPms;
    return eImageX.processUrls(scene, urls, config);
};
eImageX.directive = function (Vue, options = {}) {
    const name = options.name || 'imagex';
    const _directiveFunc = async (el, binding) => {
        console.log('binding', binding);
        const [scene, url, { srcAttr = 'src', ciConfig = {} } = {}] = binding.value;
        const { modifiers: directiveTypeObj } = binding;
        if (!scene) {
            throw new Error(`\n 请设置对应场景示例: (v-${name}=['avator','https://xxx.com/xxx.png']) \n 【详见 https://fe.xiaoe-tools.com/portal/docs/imagex/imagex.html 】`);
        }
        const directiveType = Object.values(DirectiveType).find(type => directiveTypeObj[type]) || DirectiveType.DEFAULT;
        const src = await eImageX.async(scene, url, ciConfig);
        if (directiveType === DirectiveType.BACKGROUND) {
            el.style.backgroundImage = `url(${src})`;
        }
        else {
            const [current, lastAttr] = getNestedData(el, srcAttr);
            current[lastAttr] = src;
        }
    };
    const _directiveUpdateCallback = (el, binding) => {
        if (binding.value != binding.oldValue) {
            _directiveFunc(el, binding);
        }
    };
    Vue.directive(name, {
        // vue2
        inserted: (el, binding) => _directiveFunc(el, binding),
        update: (el, binding) => _directiveUpdateCallback(el, binding),
        // vue3
        mounted: (el, binding) => _directiveFunc(el, binding),
        updated: (el, binding) => _directiveUpdateCallback(el, binding),
    });
};
// 将html字符串中img src中的链接替换成数据万象
eImageX.replaceAllImg = async (html, sence = 'richText', config) => {
    const { srcAttr = "src", ...options } = config || {};
    await eImageX.initPms;
    return html.replace(/<img[^>]*>/gi, function (str) {
        var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
        const matchInfo = str.match(srcReg);
        if (Array.isArray(matchInfo) && matchInfo.length > 1) {
            let newSrc = eImageX.sync(sence, matchInfo[1], options);
            return str.replace(matchInfo[0], `${srcAttr}="${newSrc}"`);
        }
        else {
            const content = `${srcAttr}="${eImageX.sync("custom", "https://commonresource-1252524126.cdn.xiaoeknow.com/image/m1ioijik0iys.png")}"`;
            return str.replace('<img', `<img ${content}`);
        }
    });
};
export default eImageX;
