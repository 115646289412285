// 本地localStorage标识
export const LOCAL_WEBP_MARK = "xe_webp_is_support";
export const V2_MARK = "imageView2";
export const M2_MARK = "imageMogr2";
// 失败后返回原图的数据万象参数
export const CATCH_ERROR_MARK = `|${M2_MARK}/ignore-error/1`;
export var INIT_STATUS;
(function (INIT_STATUS) {
    INIT_STATUS["READY"] = "ready";
    INIT_STATUS["DOING"] = "doing";
    INIT_STATUS["DONE"] = "done";
})(INIT_STATUS || (INIT_STATUS = {}));
export const STATIC_FORMATS = ["jpg", "jpeg", "png", "bmp", "tpg", "heif"];
